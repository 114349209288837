import { Component, Vue, Prop } from 'vue-property-decorator'

//models
import { ListPanelIconsModel } from '@/molds/ListPanelIconsModel'

//wrappers
import {
  WButton,
  wIcon,
	Tag,
	WColumns, 
	WColumn
} from '@/components/wrappers';

@Component({
  components: {
    WButton,
    wIcon,
		Tag,
		WColumns, 
		WColumn
  }
})
export default class ListPanel extends Vue {
  @Prop() config!: ListPanelIconsModel;
  @Prop() painelDataList!: object;
  @Prop() tags!: boolean;

	status (item: any): any {
		let label, type, title
		let clas = ""

    switch (item.status) {
			case "1":
				label = "Convidado"
				type = "theme-bg-c"
				title = `${item.name} está pendente de solicitação`
				break;
			case "2":
				label = "Aceitar solicitação"
				type = "is-warning"
				title = `${item.name} solicitou entrada`
				clas = "is-clickable"
				break;
			case "3":
				label = "Membro"
				type = "is-success"
				title = `${item.name} faz parte do grupo`
				break;
			default:
				label = "desconhecido"
				type = "is-danger"
				title = `status desconhecido: ${item.status}`
				break;
		}
		return {
			label: label,
			type: type,
			title: title
		}
	}

  clickStatus(row: any): void {
    this.$emit('clickStatus', row)
  }
  
	clickBtnEdit(row: any): void {
    this.$emit('clickEdit', row)
  }

  clickBtnDelete(row: any): void {
    this.$emit('clickDelete', row)
  }
}
