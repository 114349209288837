import { Component, Vue } from 'vue-property-decorator'

//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'
import ListPanelIcon from '@/components/listPanelIcon/ListPanelIcon.vue';
import SearchUser from '@/components/searchUser/SearchUser.vue';

//molds
import { User } from '@/molds/User'
import { Form, Input } from '@/components/wrappers/molds/Form'
import { PermissionInfo } from '@/store/modules/permission/permission'
import { PermissionProfile } from '@/store/modules/permission/permission'
import { ListPanelIconsModel } from '@/molds/ListPanelIconsModel'
import { TableOptions } from '@/components/wrappers/molds/TableOptions';

//classes
import { Alert } from '@/services/notification';

//Vuex
const namespacePerm = 'permission';
const namespaceaUser = 'user';
import { Action, Getter } from 'vuex-class';

//wrappers
import {
	WForm, 
	Container, 
	Box, 
  WButton,
  WHr,
	WSubtitle,
	WTable,
	Loader,
	WColumn,
	WColumns,
	SizedBox,
	WTabs,
} from '@/components/wrappers';

@Component({
	components: {
		WTabs,
		WHr,
		Box,
		WForm,
		WButton,
		WSubtitle,
		Container,
		PrivateContent,
		WTable,
		Loader,
		WColumn,
		WColumns,
		SizedBox,
		ListPanelIcon,
		SearchUser
	}
})
export default class PermProfile extends Vue {
	//gets
	@Action('fetchPermissionProfile', {namespace: namespacePerm}) fetchPermissionProfile: any 
	@Action('fetchPermissionsInfo', {namespace: namespacePerm}) fetchPermissionsInfo: any 
	//puts
	@Action('updatePermissionProfile', {namespace: namespacePerm}) updatePermissionProfile: any 
	@Action('updateDataPermissionProfile', {namespace: namespacePerm}) updateDataPermissionProfile: any 
	//getters
	@Getter('permissionsInfo', {namespace: namespacePerm}) permissionsInfo!: PermissionInfo[]
	@Getter('permissionProfile', {namespace: namespacePerm}) permissionProfile!: PermissionProfile 
	@Getter('error', {namespace: namespacePerm}) error!: any

	idPermission!: string
	isLoading = true
	isLoadingPerms = true
	isLoaderTab1 = false;
	mapPermissions: any = [[],[],[],[],[],[],[]]
	isTab2 = false
	queryUsersPerfil = ""
	alert = new Alert(this.$store)

	mounted(): void {
		document.title = 'Perfil de Permissão'
		this.init()
	}

	async init(): Promise<void> {
		if(!isNaN(parseInt(this.$route.params.id))){
			this.idPermission = this.$route.params.id
			await this.dispatchPermProfile()
			await this.fetchPermsInfo()
		}
		//verifica se a tab esta visivel
		if(this.$route.query.index == "1"){
			this.tabChange(1)
		}else if(this.$route.query.index == "2"){
			this.tabChange(2)
		}
	}
	
	tabChange(index: number): void {
		//verifica se tab1 está visivel
		if(index == 1){
			document.title = 'Dados do perfil de Permissão'
		}else if(index == 2){
			if(!this.isTab2){
				this.queryUsersPerfil = `?_limit=3&status=3&idPermission=${this.idPermission}`
				this.fetchUsersPerfil(this.queryUsersPerfil)
				this.isTab2 = true
			}
		}
	}

	async dispatchPermProfile(): Promise<void> {
		await this.fetchPermissionProfile(this.idPermission)
		
		if(this.error) {
			this.alert.apiMessage("Não foi possível carregar os dados do perfil.", this.error, "danger")
		}else if(!this.permissionProfile.name){
			this.alert.danger("Descupe ocorreu um erro inesperado em dados do perfil: 5000")
		}
		this.isLoading = false
	}
	
	async fetchPermsInfo(): Promise<void> {
		await this.fetchPermissionsInfo()
		if(this.error) {
			this.alert.apiMessage("Não foi possível carregar as permissões.", this.error, "danger")
		}else{
			this.mountMapPermission()
		}
		this.isLoadingPerms = false
	}

	async updatePermissionPerfil(): Promise<void> {
		this.isLoadingPerms = true

		await this.updatePermissionProfile(this.mountPermToSend())

		if(this.error) {
			this.alert.apiMessage("Não foi possível salvar as permissões.", this.error, "danger")
		}else{
			this.alert.success("Perfil salvo com sucesso!")
		}

		this.isLoadingPerms = false
	}

	async updateDatePermissionPerfil(permissionProfile: PermissionProfile): Promise<void> {
		this.isLoading = true
		
		const data = {
			idPermission: this.idPermission,
			permissionProfile: permissionProfile
		}		

		await this.updateDataPermissionProfile(data)
		
		if(this.error) {
			this.alert.apiMessage("Não foi possível salvar os dados da permissão.", this.error, "danger")
		}else{
			this.alert.success("Dados do perfil salvo com sucesso!")
			await this.dispatchPermProfile()
		}
		this.isLoading = false
	}

	mountMapPermission(): void {		
		let pos = -1;
		for (let i = 0; i < this.permissionsInfo.length; i++) {
			switch (this.permissionsInfo[i].category) {
				case 'groupadm':
					pos = 0
					this.mapPermissions[pos].push(this.permissionsInfo[i])
					break;
				case 'group':
					pos = 1
					this.mapPermissions[pos].push(this.permissionsInfo[i])	
					break;
				case 'groupuser':
					pos = 2
					this.mapPermissions[pos].push(this.permissionsInfo[i])
					break;
				case 'perfil':
					pos = 3
					this.mapPermissions[pos].push(this.permissionsInfo[i])
					break;
				case 'master':
					pos = 4
					this.mapPermissions[pos].push(this.permissionsInfo[i])
					break;
				case 'permission':
					pos = 5
					this.mapPermissions[pos].push(this.permissionsInfo[i])
					break;
				case 'myprocess':
					pos = 6
					this.mapPermissions[pos].push(this.permissionsInfo[i])
					break;
				default:
					break;
			}

			Object.keys(this.permissionProfile.permission || {}).map((key: string) => {
				if(pos != -1){
					let index = (this.mapPermissions[pos].length - (1))
					if(index < 0){
						index = 0
					}					
					if(this.permissionsInfo[i].fieldname == key){
						this.mapPermissions[pos][index].value = true;
					}
				}
			})
			
			pos = -1

		}
	}

	mountPermToSend(): any {
		let permission = "{"

		this.mapPermissions.forEach((arrayPermInfo: PermissionInfo[]) => {
			arrayPermInfo.forEach((permInfo: PermissionInfo) => {
				if(permInfo.value){
					permission += `"${permInfo.fieldname}":${permInfo.value},`
				}
			});

		});
		permission = permission.replace(/,\s*$/, "");
		permission += "}"

		return {
			idPermission: this.idPermission, 
			permission: JSON.parse(permission)
		}
	}

	get grid(): Input[][] {
		if(!this.isLoading){
			return [
				[
					{
						fieldName: "name",
						type: "text",
						label: "Nome",
						placeholder:"Nome",
						maxlength: 50,
						value: this.permissionProfile.name
					},
				],
				[
					{
						fieldName: "status",
						label: "Status",
						type: "select",
						maxlength: 11,
						value: this.permissionProfile.status,
						options:[
							{
								label: "Desativado",
								value: "0"
							},
							{
								label: "Ativo",
								value: "1"
							},
						]
					},
				],
			]
		}else{
			return []
		}
	}

	get form(): Form {
		return {
			flex: false,
			indent: true,
			grid: this.grid
		}
	}

	//###################### Tab 2 ###################
	//get
	@Action('fetchUsers', {namespace: namespaceaUser}) fetchUsers: any 
	//put
	@Action('updatePermissionUser', {namespace: namespaceaUser}) updatePermissionUser: any 
	//getters
	@Getter('users', {namespace: namespaceaUser}) users!: any
	@Getter('error', {namespace: namespaceaUser}) userError!: any

	isUserLoader = true
	propQuery = 'status=3'
	addUsers: User[] = []
	usersPerfil: User[] = []
	isSearchUsers = false

	listPanelIcons: ListPanelIconsModel = {
    fieldName: 'name'
  }

	async fetchUsersPerfil(query: string): Promise<void> {
		this.isUserLoader = true
		await this.fetchUsers(query)

		if(this.userError){
			this.alert.apiMessage("Não foi possível obter os usuários do perfil.", this.userError, "danger")
		}else{
			this.usersPerfil = this.users.list
		}
		this.isUserLoader = false
	}

	async dispatchUpdateUser(user: User): Promise<void> {
		const data = {
			permission: {idPermission: this.idPermission},
			idUser: user.idUser
		}

		await this.updatePermissionUser(data)
		
		if(this.userError){
			this.alert.apiMessage(`Não foi possível cadastrar usuário ${user.name}.`, this.userError, "danger")
		}else{
			this.alert.success("Perfil adicionado com sucesso!")
			this.fetchUsersPerfil(this.queryUsersPerfil)
		}

	}

	removeAddUser(user: User): void {
		for (let i = 0; i < this.addUsers.length; i++) {
			if(this.addUsers[i].idUser == user.idUser){
				this.addUsers.splice(i, 1)
				break;
			}
		}
	}

	addUser(user: User): void {
		this.alert.dialogConfirm(`Deseja adicionar perfil <b>${this.permissionProfile.name}</b> para usuário <b>${user.name}</b> ?`, () => {
			this.dispatchUpdateUser(user)
		})
	}

	showSearch(): void {
		this.isSearchUsers = true
	}

	// ############# Table ##############
	get	table(): TableOptions {
		return {	
			paginated: false,
			perPage: 50,
			data: this.addUsers,
			columns: [
				{
					field: 'idUser',
					label: 'ID',
					numeric: true,
					sortable: true
				},
				{
					field: 'name',
					label: 'Nome',
					sortable: true
				},
				{
					field: 'document',
					label: 'Documento',
					sortable: true
				},
			]
		}
	}
}
